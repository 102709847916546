import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Config } from "../data/config";

@Injectable({
  providedIn: "root"
})
export class UserService {
  service = "api/user/";
  constructor(private _http: HttpClient, private _config: Config) {}

  /** All api related to user */
  private getEmailUrl =
    "http://192.168.0.106:3000/" + this.service + "all-emails/";
  // private getAllEmailUrl =
  //   "http://192.168.0.106:3000/" + this.service + "get-all-emails";
  private getAllEmailUrl = this._config.apiBaseUrl + "web_get_all_users_id";
  private sendNotificationsUrl =
    this._config.apiBaseUrl + "web_send_notification";
  private searchUserUrl =
    this._config.apiBaseUrl + "web_search_users_from_fullname";
  private getIntegrationsUrl =
      this._config.apiBaseUrl + "web_get_user_integrations";

  /** Calls api for sending notifications to selected users */

  sendNotifications(data) {
    let options = this._config.getHeader();
    return this._http.post<any>(this.sendNotificationsUrl, data, options);
  }

  /** Calls api for getting limited Email from provided upper bound */

  getEmails(up) {
    let options = this._config.getHeader();
    return this._http.get<any>(this.getEmailUrl + up, options);
  }

  /** Calls api for getting All Emails */

  getAllEmails() {
    let options = this._config.getHeader();
    return this._http.get<any>(this.getAllEmailUrl, options);
  }

  /** Calls api for searching users by their name */

  searchUser(data) {
    let options = this._config.getHeader();
    return this._http.post<any>(this.searchUserUrl, data, options);
  }

  getAllIntegrations(data) {
    let options = this._config.getHeader();
    return this._http.post<any>(this.getIntegrationsUrl, data, options);
  }
}

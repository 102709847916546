import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Config } from "../data/config";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DeviceService {
  sendTz = "";
  constructor(private _http: HttpClient, private _config: Config) {
    let tz = new Date().getTimezoneOffset();
    if (tz < 0) {
      tz = Math.abs(tz);
      this.sendTz = "+";
    } else {
      this.sendTz = "-";
    }
    let hours = Math.floor(tz / 60);
    let minutes = tz % 60;
    this.sendTz = this.sendTz + hours + ":" + minutes;
  }

  service = "device/";

  /** All urls of apis related to device data */

  private getUserDetailsUrl =
    this._config.apiBaseUrl + "web_get_user_and_device_details";
  private getDeviceWarrantyDetailUrl =
    this._config.apiBaseUrl + "web_get_remain_warranty_period_report";
  private deallocateDeviceUrl =
    this._config.apiBaseUrl + "web_device_deallocate";
  private updateWarrantyUrl =
    this._config.apiBaseUrl + this.service + "edit-warranty";
  private deviceHistoryUrl = this._config.apiBaseUrl + "app_get_device_history";
  // private getMyDeviceUrl = this._config.apiBaseUrl + this.service + "my-device";
  // private getAlldeviceUrl =
  //   this._config.apiBaseUrl + this.service + "all-device";
  // private countDeviceUrl =
  //   this._config.apiBaseUrl + this.service + "get-device-detail";
  // private deviceByDateUrl =
  //   this._config.apiBaseUrl + this.service + "devices-by-date/";
  // private deviceByMonthUrl =
  //   this._config.apiBaseUrl + this.service + "devices-by-month/";
  // private deviceByYearUrl =
  //   this._config.apiBaseUrl + this.service + "devices-by-year/";
  // private getAllYearUrl =
  //   this._config.apiBaseUrl + this.service + "get-all-year";
  // private getDashboardDetailUrl =
  //   this._config.apiBaseUrl + this.service + "get-total-sale";
  // private getWarrantyByMonthUrl =
  //   this._config.apiBaseUrl + this.service + "get-warranty-detail/";
  private deviceGuestUserUrl = this._config.apiBaseUrl + "web_get_guest_user";

  /** Calls the api for getting the user's detail */

  getUserDetail(id): Observable<any> {
    let options = this._config.getHeader();
    return this._http.post<any>(this.getUserDetailsUrl, id, options);
  }

  /** Calls api for getting device detail whose warranty remaining for provided month */

  getDeviceWarrantyDetail(months, require_detail) {
    let options = this._config.getHeader();
    return this._http.post<any>(
      this.getDeviceWarrantyDetailUrl,
      { month: months, require_details: require_detail },
      options
    );
  }

  deallocateDevices(device) {
    let options = this._config.getHeader();
    return this._http.post<any>(this.deallocateDeviceUrl, device, options);
  }

  /**  Calls api for updating warranty of particular device */

  updateWarranty(deviceData) {
    let options = this._config.getHeader();
    return this._http.put<any>(this.updateWarrantyUrl, deviceData, options);
  }

  /** Calls api for getting history of devices */

  getHistoryOfDevices(device_id) {
    let options = this._config.getHeader();
    return this._http.post<any>(
      this.deviceHistoryUrl,
      {
        device_id: device_id,
      },
      options
    );
  }

  getGuestUsers(device_id, user_id) {
    let options = this._config.getHeader();
    return this._http.post<any>(
        this.deviceGuestUserUrl,
        {
          device_id: device_id,
          user_id: user_id,
        },
        options
    );
  }

  // /** Calls api for get detail of all devices of particular user */

  // getMyDevice() {
  //   let options = this._config.getHeader();
  //   return this._http.get<any>(this.getMyDeviceUrl, options);
  // }

  // /** Calls api for getting all devices for live report */

  // getAllDevices() {
  //   let options = this._config.getHeader();
  //   return this._http.get<any>(this.getAlldeviceUrl, options);
  // }

  // /** Calls api for getting day wise selling to display in admin dash-board */

  // countDevices() {
  //   let options = this._config.getHeader();
  //   return this._http.get<any>(
  //     this.countDeviceUrl + "/" + this.sendTz,
  //     options
  //   );
  // }

  // /** Calls api for getting weekly selling by providing date  */

  // getDeviceByDate(date) {
  //   let options = this._config.getHeader();
  //   return this._http.get<any>(
  //     this.deviceByDateUrl + date + "/" + this.sendTz,
  //     options
  //   );
  // }

  // /** Calls api for getting monthly selling by providing month */

  // getDeviceByMonth(month) {
  //   let options = this._config.getHeader();
  //   return this._http.get<any>(
  //     this.deviceByMonthUrl + month + "/" + this.sendTz,
  //     options
  //   );
  // }

  // /** Calls api for getting yearly selling by providing year */

  // getDeviceByYear(year) {
  //   let options = this._config.getHeader();
  //   return this._http.get<any>(
  //     this.deviceByYearUrl + year + "/" + this.sendTz,
  //     options
  //   );
  // }

  // /** Calls api for getting all years detail when company sells devices */

  // getAllYear() {
  //   let options = this._config.getHeader();
  //   return this._http.get<any>(this.getAllYearUrl, options);
  // }

  // /** Calls api for getting details for dashboard */

  // getAllDashboardDetail() {
  //   let options = this._config.getHeader();
  //   return this._http.get<any>(this.getDashboardDetailUrl, options);
  // }

  // /** Calls api for getting remaining warranty detail by date */

  // getWarrantyByMonth(month) {
  //   let options = this._config.getHeader();
  //   return this._http.get<any>(this.getWarrantyByMonthUrl + month, options);
  // }
}

import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  OnDestroy,
  PLATFORM_ID, Input,
} from '@angular/core';

import { Router } from "@angular/router";
import {
  MatSnackBar,
  MatSort,
  MatPaginator,
  MatTableDataSource,
  MatDialog,
  MAT_DIALOG_DATA, MatDialogRef,
} from '@angular/material';
import { DeviceService } from "src/app/core/services/device.service";
import { Config } from "src/app/core/data/config";
import { ConfirmDialog } from "../../device/device-warranty/device-warranty.component";
import { TranslateService } from "@ngx-translate/core";
import { isPlatformBrowser } from "@angular/common";
import { AuthenticationService } from "src/app/core/services/authentication.service";
import { InteractionService } from "src/app/core/services/interaction.service";
import { BeaconConfigService } from "src/app/core/services/beacon-config.service";
import { DataExchangeService } from "src/app/core/services/data-exchange.service";
import { FreeTextSearchDialogComponent } from "src/app/shared/free-text-search-dialog/free-text-search-dialog.component";
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: "app-user-detail",
  templateUrl: "./user-detail.component.html",
  styleUrls: ["./user-detail.component.scss"],
})
export class UserDetailComponent implements OnInit, OnDestroy {
  device_id: string;
  mqttConnectionBit = false;
  connection = new Array();
  userDetail: UserDetail = {
    user_full_name: "",
    id: null,
    role: null,
    email: "",
  };

  deviceDetail: DeviceDetail[] = new Array();
  deviceDetailDialogRef: MatDialogRef<DialogDeviceDetail>;
  garageHistory= new Array();

  displayedColumns: string[] = [
    "device_status",
    "no",
    "id",
    "device_name",
    "status",
    "address",
    "garage_count",
    "latitude",
    "firmware_version",
    "action",
  ];
  integrationDisplayedColumns: string[] = [
    "number",
    "user_full_name",
    "meta_value"
  ];
  fcmDisplayedColumns: string[] = [
    "number",
    "meta_key",
    "meta_value",
    "datetime"
  ];

  doorSettings: DoorSetting[] = new Array();
  doorSettingsDataSource = new MatTableDataSource<DoorSetting>(
    this.doorSettings
  );

  dataSource = new MatTableDataSource<DeviceDetail>(this.deviceDetail);

  allDevice: DeviceDetail[] = new Array();
  allIntegration: IntegrationDetail[] = new Array();
  allMetas: any[] = new Array();
  integrations: IntegrationDetail[] = new Array();
  integrationDataSource = new MatTableDataSource<IntegrationDetail>(
    this.allIntegration
  );
  fcmDetail: FcmDetail[] = new Array();
  fcmDataSource = new MatTableDataSource<FcmDetail>(this.fcmDetail);
  currentPage = 1;
  totalPages = 1;
  filter = "";
  ifilter = "";
  ffilter="";
  icurrentPage = 1;
  itotalPages = 1;
  metaData: any = [];
  iconBaseUrl;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild("iPaginator", { static: true }) iPaginator: MatPaginator;
  @ViewChild("iSort", { static: true }) iSort: MatSort;

  @ViewChild("fPaginator", { static: true }) fPaginator: MatPaginator;
  @ViewChild("fSort", { static: true }) fSort: MatSort;
  // @ViewChild(MatSort, { static: true }) integrationSort: MatSort;
  // @ViewChild(MatPaginator, { static: true }) integrationPaginator: MatPaginator;

  constructor(
    private _authService: AuthenticationService,
    private _router: Router,
    private _showMessage: MatSnackBar,
    private _deviceService: DeviceService,
    private _translate: TranslateService,
    private _config: Config,
    public dialog: MatDialog,
    private _interactionService: InteractionService,
    private _dataExchangeService: DataExchangeService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      document.title = "Admin Dashboard | Tailwind";
    }
    this.iconBaseUrl = this._config.iconBaseUrl;
  }

  ngOnInit() {
    this._interactionService.changeMenu(2);
    if (isPlatformBrowser(this.platformId)) {
      if (!localStorage.getItem("token")) {
        this._router.navigate([""]);
      } else {
        this._authService.verifyUser("admin", 2);
      }
    }
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.integrationDataSource.paginator = this.iPaginator;
    this.integrationDataSource.sort = this.iSort;
    this.fcmDataSource.paginator = this.fPaginator;
    this.fcmDataSource.sort = this.fSort;
  }

  /** It will open a model for free text search and getting user id from model and display data of that user  */

  goToFreeTextSearch() {
    let dialogRef = this.dialog.open(FreeTextSearchDialogComponent, {
      width: "700px",
      height: "700px",
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data && data.id) {
        this.device_id = data.id;
        this.getDeviceData();
      }
    });
  }

  /** Unsubscribe the MQTT Connections obsevable */

  ngOnDestroy() {
    for (let i = 0; i < this.connection.length; i++) {
      this.connection[i].unsubscribe();
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyIntegrationFilter(filterValue: string) {
    this.integrationDataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFcmFilter(filterValue: string) {
    this.fcmDataSource.filter = filterValue.trim().toLowerCase();
  }

  // Return the mac address from device_id

  getMacAddress(device_id) {
    let mac = device_id.split("_");
    mac.shift();
    mac.pop();

    for (let i = 0; i < mac.length; i++) {
      if (mac[i].length < 2) {
        mac[i] = "0" + mac[i];
      }
    }

    mac = mac.join(":");
    return mac;
  }

  // Filter the cards for mobile view

  filterCard() {
    this.dataSource.filter = this.filter.trim().toLowerCase();
    this.allDevice = this.dataSource.filteredData;
    this.totalPages = this.allDevice.length / 10;
    this.totalPages = Math.ceil(this.totalPages);
    this.deviceDetail = new Array();
    if (this.allDevice.length > 0) {
      for (let i = 0; i < 10; i++) {
        if (i < this.allDevice.length) {
          this.deviceDetail.push(this.allDevice[i]);
        } else {
          break;
        }
      }
      this.currentPage = 1;
    } else {
      this.deviceDetail = new Array();
      this.currentPage = 0;
    }
  }

  filterIntegration() {
    this.integrationDataSource.filter = this.ifilter.trim().toLowerCase();
    this.allIntegration = this.integrationDataSource.filteredData;
    this.itotalPages = this.allIntegration.length / 10;
    this.itotalPages = Math.ceil(this.itotalPages);
    this.integrations = new Array();
    if (this.allIntegration.length > 0) {
      for (let i = 0; i < 10; i++) {
        if (i < this.allIntegration.length) {
          this.integrations.push(this.allIntegration[i]);
        } else {
          break;
        }
      }
      this.icurrentPage = 1;
    } else {
      this.integrations = new Array();
      this.icurrentPage = 0;
    }
  }

  // Get & Set Device Details of given user

  getDeviceData() {
    if (this.device_id != "" && this.device_id != undefined) {
      let inputData = { id: this.device_id };
      this._dataExchangeService.changeProgress(true);
      this._deviceService.getUserDetail(inputData).subscribe(
        (res) => {
          this._dataExchangeService.changeProgress(false);
          //@ts-ignore
          if (res.status == 1) {
            //@ts-ignore
            if (res.devices.length > 0) {
              if(res.devices[0].user_id==0){
                if (isPlatformBrowser(this.platformId)) {
                  window.document.getElementById("device-table").style.display =
                    "none";
                }
                this._translate.get("NoRegisterUser").subscribe((data) => {
                  this._config.showMessage(data);
                });
              }
              else{
              let data = res.user[0];
              if (data) {
                this.userDetail = {
                  email: data.email,
                  id: data.id,
                  user_full_name: data.user_full_name,
                  role: data.role,
                };
              } else {
                window.document.getElementById("device-table").style.display =
                  "none";
                window.document.getElementById("mobile-view").style.display =
                  "none";
              }

              //@ts-ignore
              // if (res.devices.length > 0) {
              //@ts-ignore
              this.allIntegration = res.integrations;
              this.allMetas = res.meta_details;
              //@ts-ignore
              let integration_details = res.integrations_meta_details;
              if (
                this.allIntegration != undefined &&
                this.allIntegration.length > 0
              ) {
                for (let i = 0; i < this.allIntegration.length; i++) {
                  try {
                    this.allIntegration[i].meta_value = integration_details
                      .find((item) => item.user_id == this.allIntegration[i].id)
                      .meta_value.split(",");
                  } catch (error) {
                    this.allIntegration[i].meta_value = [];
                  }
                }
              }
              if (this.allMetas != undefined && this.allMetas.length > 0) {
                for (let index = 0; index < this.allMetas.length; index++) {
                  const element = this.allMetas[index];
                  if (element.meta_key.startsWith("Notification_Token")) {
                    if (element.meta_value.length > 100) {
                      this.metaData.push(element);
                      this.fcmDataSource = new MatTableDataSource<FcmDetail>(
                        this.metaData
                      );
                      this.fcmDataSource.paginator = this.fPaginator;
                      this.fcmDataSource.sort = this.fSort;
                    }
                  }
                }
              }
              this.integrationDataSource = new MatTableDataSource<any>(
                this.allIntegration
              );
              this.integrationDataSource.paginator = this.iPaginator;
              this.integrationDataSource.sort = this.iSort;
              this.deviceDetail = new Array();
              //@ts-ignore
              data = res.devices;
              if (data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                  if (
                    data[i].beacon_config != undefined &&
                    data[i].beacon_config.length > 0
                  ) {
                    for (let j = 0; j < data[i].beacon_config.length; j++) {
                      if (data[i].beacon_config[j].uuid.length == 17) {
                        data[i].beacon_config[j].type = "VB";
                      } else {
                        data[i].beacon_config[j].type = "TS";
                      }
                      if (
                        data[i].garages.find(
                          (item) =>
                            item.id == data[i].beacon_config[j].garage_id
                        )
                      ) {
                        data[i].beacon_config[j].doorname = data[
                          i
                        ].garages.find(
                          (item) =>
                            item.id == data[i].beacon_config[j].garage_id
                        ).garage_name;
                      } else {
                        data[i].garages.splice(j, 1);
                      }
                    }
                  }
                  this.deviceDetail[i] = {
                    device_status: 0,
                    no: i + 1,
                    id: data[i].id,
                    device_name: data[i].device_name,
                    status: data[i].status,
                    address: data[i].address,
                    garage_count: data[i].garage_count,
                    latitude: data[i].latitude,
                    longitude: data[i].longitude,
                    device_time_zone: data[i].device_time_zone,
                    firmware_version: data[i].firmware_version,
                    garages_name: data[i].garages_name,
                    onlineChecker: false,
                    //@ts-ignore
                    user_id: data[i].user_id,
                    //@ts-ignore
                    is_share_device: data[i].is_shared_device,
                    //@ts-ignore
                    notifications: data[i].notification_setting,
                    //@ts-ignore
                    sensor: data[i].beacon_config,
                    //@ts-ignore
                    version: data[i].versions,
                  };
                }
              }
              this.dataSource = new MatTableDataSource<DeviceDetail>(
                this.deviceDetail
              );

              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              if (isPlatformBrowser(this.platformId)) {
                if (window.screen.width < 1024) {
                  this.allDevice = this.deviceDetail;
                  delete this.deviceDetail;
                  this.deviceDetail = new Array();
                  let limit;
                  if (this.allDevice.length < 10) {
                    limit = 10 - (10 - this.allDevice.length);
                  } else {
                    limit = 10;
                  }
                  for (let i = 0; i < limit; i++) {
                    this.deviceDetail.push(this.allDevice[i]);
                  }
                  this.totalPages = this.allDevice.length / 10;
                  this.totalPages = Math.ceil(this.totalPages);

                  /** Integration Pagination */

                  if (this.allIntegration.length < 10) {
                    limit = 10 - (10 - this.allIntegration.length);
                  } else {
                    limit = 10;
                  }
                  for (let i = 0; i < limit; i++) {
                    this.integrations.push(this.allIntegration[i]);
                  }
                  this.itotalPages = this.allIntegration.length / 10;
                  this.itotalPages = Math.ceil(this.itotalPages);

                  window.document.getElementById("mobile-view").style.display =
                    "block";
                  window.document.getElementById("mobile-view").style.width =
                    window.screen.width.toString();
                  window.document.getElementById("device-table").style.display =
                    "none";
                } else {
                  window.document.getElementById("device-table").style.display =
                    "block";
                  window.document.getElementById("mobile-view").style.display =
                    "none";
                }
                this.checkMqttConnection();
                // } else {
                //   this._translate
                //     .get("InvalidDeviceUserEmail")
                //     .subscribe(message => {
                //       this._config.showMessage(message);
                //     });
                // }
              } else {
                if (isPlatformBrowser(this.platformId)) {
                  window.document.getElementById("device-table").style.display =
                    "none";
                }

                //@ts-ignore
                this._config.showMessage(res.message);
              }
            }
            } else {
              if (isPlatformBrowser(this.platformId)) {
                window.document.getElementById("device-table").style.display =
                  "none";
              }
              this._translate.get("NoDevice").subscribe((data) => {
                this._config.showMessage(data);
              });
            }
          }
        },
        (err) => {
          this._dataExchangeService.changeProgress(false);
          if (isPlatformBrowser(this.platformId)) {
            window.document.getElementById("device-table").style.display =
              "none";
          }

          this._translate.get("NoResponseFound").subscribe((data) => {
            this._config.showMessage(data);
          });
        }
      );
    } else {
      this._translate.get("ValidDeviceUserEmail").subscribe((data) => {
        this._config.showMessage(data);
      });
    }
  }

  // Check Device is Connected with Mqtt or not

  async checkMqttConnection() {
    for (let i = 0; i < this.deviceDetail.length; i++) {
      /**
       * Subscribing the Mqtt topic
       */
      this.connection[i] = this._config._mqttService
        .observe(this.deviceDetail[i].id + this._config.topics.status)
        .subscribe((data) => {
          let recievedData = parseInt(data.payload.toString());
          if (recievedData >= 0 && recievedData <= 7) {
            if (isPlatformBrowser(this.platformId)) {
              window.document.getElementById(
                "connection" + this.deviceDetail[i].id
              ).style.background = "#449d44";
              window.document.getElementById(
                "mobileconnection" + this.deviceDetail[i].id
              ).style.background = "#449d44";
            }

            this.deviceDetail[i].onlineChecker = true;
            this._showMessage.dismiss();
          }
        });

      /**
       * After connection of the Mqtt ,it will publish the command 9
       */
      this._config._mqttService.onConnect.subscribe((data) => {
        this._config._mqttService.unsafePublish(
          this.deviceDetail[i].id + this._config.publish.command,
          "9",
          { qos: 1, retain: false }
        );
      });
      /**
       * It will publish the command 9
       */
      await this._config._mqttService.unsafePublish(
        this.deviceDetail[i].id + this._config.publish.command,
        "9",
        { qos: 1, retain: false }
      );
    }
  }

  /**
   *
   * @param device Device Object of which user want to update firmware
   */

  editFirmware(device) {
    let topic = this._config._mqttService
      .observe(device.id + this._config.topics.status)
      .subscribe((data) => {
        let recievedData = parseInt(data.payload.toString());
        if (recievedData >= 0 && recievedData <= 7) {
          this.updateFirmware(device);
          topic.unsubscribe();
        } else {
          this._translate.get("DeviceNotOnline").subscribe((data) => {
            this._config.showMessage(data);
          });
        }
      });
    this._config._mqttService.unsafePublish(
      device.id + this._config.publish.command,
      "9",
      { qos: 1, retain: false }
    );
  }

  /**
   * It will ask the confirmation from user and if user five yes response it will publish the deallocation command -9 after getting the success response of the call of deallocation service
   * @param device Device Object which user want to deallocate
   */

  deallocateDevice(device) {
    let dialog = this.dialog.open(ConfirmDialog, {
      data: "Do you want to Deallocate the Device?",
    });
    dialog.afterClosed().subscribe((data) => {
      if (data) {
        this._deviceService
          .deallocateDevices({ device_id: device.id })
          .subscribe(
            (res) => {
              this._config._mqttService.unsafePublish(
                device.id + this._config.publish.command,
                "-9",
                { qos: 1, retain: false }
              );
              //@ts-ignore
              this._config.showMessage(res.message);
              this.deviceDetail = this.deviceDetail.filter(
                (item) => item.id != device.id
              );
              this.dataSource = new MatTableDataSource<DeviceDetail>(
                this.deviceDetail
              );
            },
            (err) => {
              this._translate.get("NotDeallocate").subscribe((message) => {
                this._config.showMessage(message);
              });
            }
          );
      }
    });
  }

  /**
   * It will get the confirmation from the User and if user accept the request for update firmware,it publish the firmware updation command 8
   * @param device Device Object of which user want to update the firmware
   */

  updateFirmware(device) {
    let dialogRef;
    this._translate.get("UpdateFirmwareMessage").subscribe((data) => {
      dialogRef = this.dialog.open(ConfirmDialog, {
        data:
          data +
          device.device_name +
          "(" +
          this.getMacAddress(device.id) +
          ") ?",
      });
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._config._mqttService.unsafePublish(
          device.id + this._config.publish.command,
          "8",
          { qos: 1, retain: false }
        );
        this._translate.get("FirmwareUpdatedMessage").subscribe((data) => {
          this._config.showMessage(data);
        });
      }
    });
  }

  /**
   * First, it will get confirmation for restarting the device then it will publish the device restart commanf -9
   * @param device Device Object which user want to restart.
   */

  async restartDevice(device) {
    if (device.onlineChecker) {
      let dialogRef;
      this._translate.get("RestartDevice").subscribe((data) => {
        dialogRef = this.dialog.open(ConfirmDialog, {
          data:
            data +
            device.device_name +
            "(" +
            this.getMacAddress(device.id) +
            ") ?",
        });
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          let topic = this._config._mqttService
            .observe(device.id + this._config.topics.door_status)
            .subscribe((data) => {
              if (data.payload.toString() == "-9") {
                this._translate.get("RestartingDevice").subscribe((data) => {
                  this._showMessage.open(data, "dismiss", {
                    duration: 5000,
                    verticalPosition: this._config.verticalPlace,
                    horizontalPosition: this._config.horizontalPlace,
                  });
                });

                topic.unsubscribe();
                if (isPlatformBrowser(this.platformId)) {
                  window.document.getElementById(
                    "connection" + device.id
                  ).style.background = "rgb(227, 167, 39)";
                  window.document.getElementById(
                    "mobileconnection" + device.id
                  ).style.background = "rgb(227, 167, 39)";
                }
              }
            });
          this._config._mqttService.unsafePublish(
            device.id + this._config.publish.command,
            "-9",
            { qos: 1, retain: false }
          );
        }
      });
    }
  }

  /**
   * It will open the Device Detail dialog for selected device.
   * @param device Device Object of which user want to get detail.
   */

  async getDeviceDetails(device) {
    let isHistoryEnable: boolean = true;
    let garegeNames = device.garages_name.split(",");
    this.doorSettings = [];
    for (let i = 0; i < device.garage_count; i++) {
      let inc = 1;
      if (i == 2) inc = 2;
      this.doorSettings[i] = {
        id: i + inc,
        name: garegeNames[i],
        status: "-",
        close_setting_pulse: "-",
        open_setting_pulse: "-",
        close_pulse_time: "-",
        open_pulse_time: "-",
        partial_open_time: "-",
        door_open_url:
          "https://connect.gotailwind.com/garage_app_ws/images/door_variant/0/garage_40.png",
        door_close_url:
          "https://connect.gotailwind.com/garage_app_ws/images/door_variant/0/garage_1.png",
      };
    }
    for (let i = 0; i < this.allMetas.length; i++) {
      for (let j = 0; j < device.garage_count; j++) {
        let sufix = device.id + this.doorSettings[j].id;
        if (this.allMetas[i].meta_key === "Door_Disabled" + sufix) {
          var d = new Date();
          var n = d.getTime();
          let time = this.allMetas[i].meta_value * 1;
          this.doorSettings[j].status = time < n ? "-" : "Disable till\n";

          if (time > n) {
            var date = new Date(time);
            this.doorSettings[j].status += date.toLocaleString("en-US", {
              timeZone: device.device_time_zone,
            });
          }
          break;
        }
        /**
         * close door setting pulse
         */
        if (this.allMetas[i].meta_key === "Door_Nickname" + sufix) {
          this.doorSettings[j].name = this.allMetas[i].meta_value;
          break;
        }

        /**
         * close door setting pulse
         */
        if (
          this.allMetas[i].meta_key ===
          "Close_Door_Settings_Pulses" + sufix
        ) {
          this.doorSettings[j].close_setting_pulse =
            this.allMetas[i].meta_value;
          break;
        }

        /**
         * Open door setting pulse
         */
        if (this.allMetas[i].meta_key === "Open_Door_Settings_Pulses" + sufix) {
          this.doorSettings[j].open_setting_pulse = this.allMetas[i].meta_value;
          break;
        }

        /**
         * Open pulse time
         */
        if (this.allMetas[i].meta_key === "Open_Pulse_Time" + sufix) {
          this.doorSettings[j].open_pulse_time = this.allMetas[i].meta_value;
          break;
        }

        /**
         * Close pulse time
         */
        if (this.allMetas[i].meta_key === "Close_Pulse_Time" + sufix) {
          this.doorSettings[j].close_pulse_time = this.allMetas[i].meta_value;
          break;
        }
        /**
         * Partial Open Time
         */
        if (this.allMetas[i].meta_key === "Partial_Open_Time" + sufix) {
          this.doorSettings[j].partial_open_time = this.allMetas[i].meta_value;
          break;
        }

        /**
         * Partial Open Time
         */
        if (this.allMetas[i].meta_key === "history" + device.id) {
          isHistoryEnable = this.allMetas[i].meta_value === "1";
          break;
        }

        /**
         * Custom door Images
         */
        if (
          this.allMetas[i].meta_key === "door_custom_images_selected" + sufix &&
          this.allMetas[i].meta_value != "0"
        ) {
          this.doorSettings[j].door_open_url =
            "https://connect.gotailwind.com/garage_app_ws/images/door_variant/" +
            this.allMetas[i].meta_value +
            "/garage_40.png";
          this.doorSettings[j].door_close_url =
            "https://connect.gotailwind.com/garage_app_ws/images/door_variant/" +
            this.allMetas[i].meta_value +
            "/garage_1.png";
          break;
        }
      }
    }


    this.deviceDetailDialogRef = this.dialog.open(DialogDeviceDetail, {
      height: "750px",
      width: "1000px",
      data: {
        device_id: device.id,
        device: device,
        tz: device.device_time_zone,
        user_id: device.user_id,
        notifications: device.notifications,
        sensor: device.sensor,
        onlineChecker: device.onlineChecker,
        share_device: device.is_share_device,
        door_setting: this.doorSettings,
        isHistoryEnable: isHistoryEnable,
        //integrations: this.integrationDataSource,
        integrationColumns: [
          "number",
          "user_full_name",
          //"meta_value"
        ],
        garageHistory: this.garageHistory,
        showGarageHistory: true,
      },
    });
  }

  changePage(pageno) {
    delete this.deviceDetail;
    this.deviceDetail = new Array();
    let limit;

    if (pageno * 10 > this.allDevice.length) {
      limit = 10 - (pageno * 10 - this.allDevice.length);
    } else {
      limit = 10;
    }
    for (let i = 0; i < limit; i++) {
      this.deviceDetail.push(this.allDevice[pageno * 10 - 10 + i]);
    }
    this.currentPage = pageno;
  }

  changeIntegrationPage(pageno) {
    delete this.integrations;
    this.integrations = new Array();
    let limit;
    if (pageno * 10 > this.integrations.length) {
      limit = 10 - (pageno * 10 - this.allIntegration.length);
    } else {
      limit = 10;
    }
    for (let i = 0; i < limit; i++) {
      this.integrations.push(this.allIntegration[pageno * 10 - 10 + i]);
    }
    this.icurrentPage = pageno;
  }
}

export interface UserDetail {
  id: number;
  user_full_name: string;
  email: string;
  role: number;
}

export interface DoorSetting {
  id: number;
  name: string;
  status: string; //Door_Disabled_80_7d_3a_99_50_44_1
  close_setting_pulse: string; //"Close_Door_Settings_Pulses_80_7d_3a_9b_d7_54_1"
  open_setting_pulse: string; //"Open_Door_Settings_Pulses_80_7d_3a_9b_d7_54_1"
  close_pulse_time: string; //"Close_Pulse_Time_80_7d_3a_9b_d7_54_1"
  open_pulse_time: string; //"Open_Pulse_Time_80_7d_3a_9b_d7_54_1"
  partial_open_time: string; //"Partial_Open_Time_80_7d_3a_99_50_44_1"
  door_open_url: string; //"Partial_Open_Time_80_7d_3a_99_50_44_1"
  door_close_url: string; //"Partial_Open_Time_80_7d_3a_99_50_44_1"
}

export interface DeviceDetail {
  device_status: number;
  no: number;
  id: string;
  device_name: string;
  status: string;
  garages_name: string;
  address: string;
  garage_count: number;
  latitude: string;
  longitude: string;
  device_time_zone: string;
  firmware_version: number;
  onlineChecker: boolean;
  is_share_device: boolean;
}

export interface IntegrationDetail {
  address: string;
  created_date: Date;
  device_token: string;
  device_type: number;
  email: string;
  esp_device_id: string;
  first_name: string;
  gh_tokean: string;
  guid: string;
  id: number;
  ifttt_token: string;
  integration_token: string;
  is_active: boolean;
  is_android_logged: boolean;
  is_delete: boolean;
  is_ios_logged: boolean;
  is_testdata: string;
  last_name: string;
  latitude: number;
  longitude: number;
  modified_date: Date;
  parent_id: number;
  passsword: string;
  profile_picture: string;
  role: number;
  user_full_name: string;
  uuid: string;
  meta_value: Array<any>;
}

export interface FcmDetail {
  key: string;
  value: string;
}

@Component({
  selector: "device-detail-dialog",
  templateUrl: "./device-detail.html",
  styleUrls: ["./device-detail.scss", "../../../client/devices/my-device/action-dialog.component.scss"],
})
export class DialogDeviceDetail implements OnInit {
  doorStatus: boolean[] = [false, false, false];
  data: any = {};
  totalGarageDoors;
  doorLocks = new Array();
  vehicleSensorData = new Array();
  doorSettings = new Array();
  status;
  processCompleted = false;
  imageBaseUrl;
  nightModeTime;
  event_type: boolean = false;
  schedule_access: boolean = false;
  notification_bit = 0;
  verbalNotification = 0;
  eventTypes: any = {};
  header = new Array();
  notifyDoorLeft = 0;
  timeDuration: number;
  automaticClose = 0;
  schedules = new Array();
  notifications = new Array();
  nightModeEndTime;
  dataSource = new MatTableDataSource();
  integrationDataSource = new MatTableDataSource<IntegrationDetail>();
  @Input() garageHistory = new Array();
  sharedAccess = new Array();
  integrations = new Array();

  doorSettingsDataSource = new MatTableDataSource();

  displayedColumns: string[] = [
    "beaconId",
    "user_full_name",
    "garage_id",
    "major",
    "minor",
    "vehicle_name",
    "open_distance",
    "close_distance",
  ];

  doorSettingColumns: string[] = [
    "garage_id",
    "garage_name",
    "garage_status",
    "close_setting_pulse",
    "open_setting_pulse",
    "close_pulse_time",
    "open_pulse_time",
    "partial_open_time",
  ];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    @Inject(MAT_DIALOG_DATA) public recievedData,
    private config: Config,
    private _beaconConfigService: BeaconConfigService,
    private _config: Config,
    private _deviceService: DeviceService,
    private _userService: UserService,
  ) {
    this.imageBaseUrl = this.config.imageBaseUrl;
    this.vehicleSensorData = this.recievedData.sensor;
    this.doorSettings = this.recievedData.door_setting;
    this.notifications = this.recievedData.notifications;
    let data = this.notifications.filter((item) => item.id == item.event_type);
    let column = new Array();
    for (let i = 0; i < data.length; i++) {
      column.push(
        this.notifications.filter(
          (item) =>
            item.event_type == data[i].event_type &&
            item.id != data[i].event_type
        )
      );
    }
    this.header = data;
    this.notifications = column;
  }

  async ngOnInit() {
    /**
     * It will subscribe the topic for getting device details and according to detail set the doors status,night mode detail.
     * It also call service for getting the Notification Settings.
     */
    let history = new Array();

    this._deviceService.getHistoryOfDevices(this.recievedData.device_id).subscribe(
        (res) => {
          //@ts-ignore
          if (res.status == 1) {
            //@ts-ignore
            history = res.DoorHistory;
            for (let i = 0; i < history.length; i++) {
              let ctime = new Date(history[i].datetime);
              //@ts-ignore
              ctime = new Date(ctime.toUTCString()).toLocaleString("en-US", {
                timeZone: this.recievedData.tz,
                // timeZone: "America/Pacific"
              });
              //@ts-ignore
              ctime = new Date(ctime + " GMT").toLocaleString("en-US", {
                timeZone: this.recievedData.tz,
                //timeZone: "America/Pacific"
              });
              history[i].datetime = ctime;
            }
            let filterdata = new Array();
            for (let i = 0; i < history.length; i++) {
              if (i == 0) {
                filterdata.push({
                  datetime: history[i].datetime,
                  data: history.filter(
                      (item) =>
                          new Date(item.datetime).getDate() ==
                          new Date(history[i].datetime).getDate()
                  ),
                });
              } else {
                if (
                    new Date(history[i].datetime).getDate() !=
                    new Date(history[i - 1].datetime).getDate()
                ) {
                  filterdata.push({
                    datetime: history[i].datetime,
                    data: history.filter(
                        (item) =>
                            new Date(item.datetime).getDate() ==
                            new Date(history[i].datetime).getDate()
                    ),
                  });
                }
              }
            }
            history = filterdata;
            this.garageHistory = history;

          } else {
          }
        },
        (err) => {
        }
    );

    this._deviceService.getGuestUsers(this.recievedData.device_id, this.recievedData.user_id).subscribe(
        (res) => {
          //@ts-ignore
          if (res.status == 1) {
            //@ts-ignore
            this.sharedAccess = res.guest_users
          } else {
          }
        },
        (err) => {
        }
    );

    this._userService.getAllIntegrations({user_id: this.recievedData.user_id}).subscribe(
        (res) => {
                 //@ts-ignore
                if (res.status == 1) {
                  //@ts-ignore
                  this.integrations = res.user
                }
        }, (err) => {}
    )


    let topic = await this.config._mqttService
      .observe(this.recievedData.device_id + this.config.topics.details)
      .subscribe((data) => {
        this.data = data.payload.toString();
        this.data = JSON.parse(this.data);
        if (this.data.night_mode_en == 0) {
          this.nightModeTime = 0;
        } else {
          this.nightModeTime = this.getTime(
            this.data.night_mode_begin,
            this.recievedData.tz
          );
          this.nightModeEndTime = this.getTime(
            this.data.night_mode_end,
            this.recievedData.tz
          );
        }
        this.totalGarageDoors = new Array(this.data.garage_count);
        if (this.data.garage_count == 1) {
          this.doorLocks.push(this.data.door1_lock);
        } else {
          if (this.data.garage_count == 2) {
            this.doorLocks.push(this.data.door1_lock);
            this.doorLocks.push(this.data.door2_lock);
          } else {
            this.doorLocks.push(this.data.door1_lock);
            this.doorLocks.push(this.data.door2_lock);
            this.doorLocks.push(this.data.door3_lock);
          }
        }
        topic.unsubscribe();
      });
    await this.config._mqttService.unsafePublish(
      this.recievedData.device_id + this.config.publish.command,
      "-3",
      { qos: 1, retain: false }
    );

    this.config._mqttService
      .observe(this.recievedData.device_id + this.config.topics.status)
      .subscribe((data) => {
        this.status = parseInt(data.payload.toString());
        this.setDoors();
      });

    await this.config._mqttService.unsafePublish(
      this.recievedData.device_id + this.config.publish.command,
      "9",
      { qos: 1, retain: false }
    );
    for (let i = 0; i < this.vehicleSensorData.length; i++) {
      this.vehicleSensorData[i].garage_id = this.vehicleSensorData[
        i
      ].garage_id.replace(this.recievedData.device_id, "");
    }
    this.dataSource = new MatTableDataSource(this.vehicleSensorData);

    this.doorSettingsDataSource = new MatTableDataSource(this.doorSettings);

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    let data;
    this._beaconConfigService
      .getNoficationSettings({ user_id: this.recievedData.user_id })
      .subscribe((res) => {
        //@ts-ignore
        if (res.status == 1) {
          //@ts-ignore
          data = res.app_settings;
          if (data.length > 0) {
            let value = data.find(
              (item) =>
                item.meta_key ==
                "Notification_Status" + this.recievedData.device_id
            );
            if (value) {
              this.notification_bit = parseInt(value.meta_value);
            }
            value = data.find(
              (item) =>
                item.meta_key ==
                "Notification_Custom_Status" + this.recievedData.device_id
            );
            if (value) {
              this.verbalNotification = parseInt(value.meta_value);
            }
            value = data.find(
              (item) =>
                item.meta_key ==
                "Notification_Left_Open" + this.recievedData.device_id
            );
            if (value) {
              this.notifyDoorLeft = parseInt(value.meta_value);
            }

            if (this.notifyDoorLeft != 0) {
              if (this.notifyDoorLeft > 0) {
                this.timeDuration = this.notifyDoorLeft;
                this.notifyDoorLeft = 1;
              } else {
                this.timeDuration = Math.abs(this.notifyDoorLeft);
                this.notifyDoorLeft = 1;
                this.automaticClose = 1;
              }
            }

            this.schedules = data.filter((item) =>
              item.meta_key.includes(
                "Notification_Interval" + this.recievedData.device_id
              )
            );
            let splitter;
            for (let i = 0; i < this.schedules.length; i++) {
              splitter = this.schedules[i].meta_value.split(",");
              splitter[0] = parseInt(splitter[0]);
              splitter[1] = parseInt(splitter[1]);
              splitter[0] = this.get24FormatTime(splitter[0] / 3600);
              splitter[1] = this.get24FormatTime(splitter[1] / 3600);
              this.schedules[i].start_time = splitter[0];
              this.schedules[i].end_time = splitter[1];
            }
          }
        } else {
        }
        //@ts-ignore
        this._config.showMessage(res.message);
      });
  }

  getEvents() {
    this.event_type = !this.event_type;
  }

  getSchedule() {
    this.schedule_access = !this.schedule_access;
  }

  /**
   * It will set the Door Status liken true => Opened, false => Closed.
   */

  setDoors() {
    switch (this.status) {
      case 1:
        this.doorStatus[0] = true;
        break;
      case 2:
        this.doorStatus[1] = true;
        break;
      case 3:
        this.doorStatus[0] = true;
        this.doorStatus[1] = true;
        break;
      case 4:
        this.doorStatus[2] = true;
        break;
      case 5:
        this.doorStatus[0] = true;
        this.doorStatus[2] = true;
        break;
      case 6:
        this.doorStatus[1] = true;
        this.doorStatus[2] = true;
        break;
      case 7:
        this.doorStatus[0] = true;
        this.doorStatus[1] = true;
        this.doorStatus[2] = true;
        break;
    }
    if (this.data.garage_count == 1) {
      this.doorStatus[1] = false;
      this.doorStatus[2] = false;
    }

    if (this.data.garage_count == 2) this.doorStatus[2] = false;
    this.processCompleted = true;
  }

  /**
   *
   * @param time Time which need to convert in 12 Hoour format.
   */

  get24FormatTime(time) {
    time = time % 24;
    if (time < 0) {
      time = 24 + time;
    }
    let str = Math.floor(time);
    time = (time - Math.floor(time)) * 100;
    time = (time * 60) / 100;
    time = parseInt(time.toFixed());
    let answer;
    if (str > 12) {
      str = str - 12;
      answer = ("0" + str).slice(-2) + " : " + ("0" + time).slice(-2) + " PM";
    } else {
      answer = ("0" + str).slice(-2) + " : " + ("0" + time).slice(-2) + " AM";
    }
    return answer;
  }

  /**
   * It will convert the UTC time to User Device's Timezone
   * @param seconds Milliseconds of time
   * @param timezone Timezone of User device.
   */

  getTime(seconds, timezone) {
    let d = new Date();
    let tzdate = new Date(
      new Date(seconds * 1000).toLocaleString("en-US", { timeZone: timezone })
    );
    let diff = new Date(seconds * 1000).getTime() - tzdate.getTime();
    diff = diff / 3600000;
    diff = diff + new Date().getTimezoneOffset() / 60;
    if (diff > 0) {
      diff = -diff;
    } else {
      diff = Math.abs(diff);
    }

    let time = (seconds + diff * 3600) / 3600;
    let answer = this.get24FormatTime(time);
    return answer;
  }
}
